body {
  background-image: url(./assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.card {
  @apply bg-[#211E1A] rounded-xl p-6;
}

.gradient-primary {
  background: linear-gradient(180deg, #f17c62 0%, #ec3251 100%);
}

.text-muted {
  @apply text-[#F6F4FF] opacity-50;
}

.clip {
  clip-path: ellipse(45% 28% at 50% 88%);
}
